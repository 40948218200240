$(function() {
        $(this).bind("contextmenu", function(e) {
            e.preventDefault();
        });
    });

// use code below to unlock keys

//$(function() {
//        document.addEventListener('contextmenu', event => event.stopPropagation(), true);
//    });
