$(document).on('turbolinks:load', function() {

   //  code below is used to ID browser
         let userAgent = window.navigator.userAgent;
         let browserName;
         
         if(userAgent.match(/chrome|chromium|crios/i)){
             browserName = "chrome";
           }else if(userAgent.match(/firefox|fxios/i)){
             browserName = "firefox";
           }  else if(userAgent.match(/safari/i)){
             browserName = "safari";
           }else if(userAgent.match(/opr\//i)){
             browserName = "opera";
           } else if(userAgent.match(/edg/i)){
             browserName = "edge";
           }else{
             browserName="No browser detection";
           }

      var testalert ="<div class='alert alert-warning'><strong>Warning</strong>! Please check your network connection</div>";
  
  // var get_div = document.getElementById("currently_playing");
  var getpath = window.location.pathname;
  //alert(browserName);
  var refreshInterval;

// branch JS code based on browser

if (browserName == "chrome") {
   // code below is executed from crome browser

  if (getpath == "/pages/aprosys_radio_now_playing") {
      refreshInterval = setInterval(function() {
            //alert(browserName);
            //$("#iframe2").load(window.location.href + " #iframe2>*");
            // $('#iframe2').attr('src', "nowplaying");
            $('#iframe2').attr('src', "https://playlist.aprosys-radio.com/public/playing.html");

        }, 180000);
  } else if (getpath == "/userpages/aprosys_radio_userhome_kbps192" || getpath == "/userpages/aprosys_radio_userhome_kbps096" ||
             getpath == "/userpages/aprosys_radio_userhome_kbps128" || getpath == "/userpages/aprosys_radio_userhome_kbps064" ) {
      refreshInterval = setInterval(function() {
           $("#zcurrent_songinfo").empty().load(getpath + " #zcurrent_songinfo", function( response, status, xhr) {
                  if (xhr.status == 401 ) {
                        var msg = "Echoing XHR values: ";
                        var tmpx = "   Value for Status is:";
                        var tmpz = "   Value for xhr.status is:";
                        var tmpy = "   Value for response is:";
                        //alert(msg + xhr.statusText + tmpx + status + tmpz + xhr.status );
                        // $("error").html( msg + xhr.status + " " + xhr.statusText );
                         console.log(msg + xhr.statusText + tmpx + status + tmpz + xhr.status + tmpy);
                        // code below works comment out for now
                        $('audio').attr('src', " ").attr("id", "player").appendTo("audio");
                        //$('audio').attr('src', " ").attr("id", "player2").appendTo("audio");
                        alert("Your login credentials were used in another browser. Please sign in again to continue in this browser.");
                        window.location = '/users/sign_in';
                        window.location.reload();

                  } else if (xhr.status != 401 ) {
                        $('#iframe1').attr('src', "https://playlist.aprosys-radio.com/public/songinfo.html");
                        //document.getElementById('iframe1').contentDocument.location.reload(true);
                        //$("#zcurrent_songinfo").empty().load(window.location.href + " #zcurrent_songinfo")
                        //console.log('xhr.status != 401');
                        //console.log(msg + xhr.statusText + tmpx + status + tmpz + xhr.status + tmpy);
                         }
            });

         }, 90000);

  } 

   
} else {
   // code below is executed for all other browsers

  if (getpath == "/pages/aprosys_radio_now_playing") {
      refreshInterval = setInterval(function() {
            //$("#iframe2").load(window.location.href + " #iframe2>*");
            //$('#iframe2').attr('src', "nowplaying");
            $('#iframe2').attr('src', "https://playlist.aprosys-radio.com/public/playing.html");

        }, 180000);
  } else if (getpath == "/userpages/aprosys_radio_userhome_kbps192" || getpath == "/userpages/aprosys_radio_userhome_kbps096" ||
             getpath == "/userpages/aprosys_radio_userhome_kbps128" || getpath == "/userpages/aprosys_radio_userhome_kbps064" ) {
      refreshInterval = setInterval(function() {
           $("#zcurrent_songinfo").empty().load(getpath + " #zcurrent_songinfo", function( response, status, xhr) {
                  if (xhr.status == 401 ) {
                        var msg = "Echoing XHR values: ";
                        var tmpx = "   Value for Status is:";
                        var tmpz = "   Value for xhr.status is:";
                        var tmpy = "   Value for response is:";
                        //alert(msg + xhr.statusText + tmpx + status + tmpz + xhr.status );
                        // $("error").html( msg + xhr.status + " " + xhr.statusText );
                       console.log(msg + xhr.statusText + tmpx + status + tmpz + xhr.status + tmpy);
                        // code below works comment out for now
                        $('audio').attr('src', " ").attr("id", "player").appendTo("audio");
                        //$('audio').attr('src', " ").attr("id", "player2").appendTo("audio");
                        alert("Your login credentials were used in another browser. Please sign in again to continue in this browser.");
                        window.location = '/users/sign_in';
                        window.location.reload();

                  } else if (xhr.status != 401 ) {
                          //$("#current_songinfo").load(window.location.href + " #current_songinfo");
                          //  block of code to be executed if the condition is false
                          //$('#iframe1').load('userpages/getsonginfo');
                          //$("#iframe1").load(window.location.href + " #iframe1 > *");
                          //$("#current_songinfo").load(window.location.href + " #current_songinfo");
                          //$("#iframe1").load(window.location.href + " #iframe1");
                          // old -   $("#current_songinfo").load(window.location.href + " #current_songinfo");
                          //  $('#zcurrent_songinfo').load('#zcurrent_songinfo')
                          // $('iframe').attr('src', "https://www.aprosys-radio.net/pictures/aprosys_radio/songinfo.html");
                          //  $('#iframe1').attr('src', "userpages/getsonginfo");
                          //document.getElementById('iframe1').contentDocument.location.reload(true);
                          //$("#zcurrent_songinfo").empty().load(window.location.href + " #zcurrent_songinfo")
                          //console.log('xhr.status != 401');
                          //console.log(msg + xhr.statusText + tmpx + status + tmpz + xhr.status + tmpy);
                        
                         // bit below was origan used that worked
                         // $('#iframe1').attr('src', "getsonginfo");
                         $('#iframe1').attr('src', "https://playlist.aprosys-radio.com/public/songinfo.html");
                         
                         
                         }
            });

         }, 90000);

  } 
   
}
  
    

$(window).on("beforeunload", function() { 
      clearInterval(refreshInterval);
});



});
